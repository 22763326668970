/**
 * @file
 * Behaviors for the fitvid.
 */

!((Drupal, $) => {
  'use strict';

  Drupal.behaviors.fitvid = {
    attach: function(context) {
      if ($('iframe', context).length) {
        fitvids();
      }
    }
  };
})(Drupal, jQuery);
